/* eslint-disable prefer-rest-params */
function contentExists(item) {
  if (Array.isArray(item)) {
    if (item.length > 0) {
      return true;
    }
  }

  if (item !== null) {
    if (item !== '') {
      return true;
    }
  }

  return false;
}

export function cmsContentExists(/* contentItemN */) {
  return Array.from(arguments).every((contentItem) => contentExists(contentItem));
}
