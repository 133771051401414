/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import ReactPlayer from "react-player"
import { Layout } from "../components/layout"
import { formatDate } from "../components/formatDate"
import { DividerBlock } from "../components/dividerBlock"
import { renderArtist } from "../components/artistList"
import { Credits } from "../components/credits"
import { cmsContentExists } from "../helpers/cms-content-exists"
import { SwiperGallery } from "../components/swiperGallery.tsx"
import ImageFadeIn from "react-image-fade-in"

const Exhibition = ({ data }) => {
  const [relatedExhibitions, setRelatedExhibitions] = useState([])
  const exhibition = data.datoCmsSingleExhibition
  const niceStartDate = formatDate(exhibition.startDate)
  const niceEndDate = formatDate(exhibition.endDate)
  const backLink = exhibition.slug
    ? `/exhibition/${exhibition.slug}`
    : "/exhibitions"
  const backLinkText = "exhibition"
  const articleTypeName = "DatoCmsSingleArticle"
  // eslint-disable-next-line arrow-body-style
  const associatedWriting = exhibition.associatedWriting.filter(article => {
    return (
      article.__typename === articleTypeName ||
      article.internal.type === articleTypeName
    )
  })

  function slugPrefix(type) {
    if (type === articleTypeName) {
      return "/article/"
    }

    if (type === "DatoCmsSingleDigitalProject") {
      return "/digital-projects/"
    }

    return "/"
  }

  useEffect(() => {
    if (exhibition.relatedExhibitions.length > 0) {
      setRelatedExhibitions(exhibition.relatedExhibitions)
    } else {
      const relatedExhibitionObj = []
      data.allDatoCmsSingleExhibition.edges.forEach(({ node: exhib }) => {
        if (exhib.id === exhibition.id) {
          return
        }
        exhibition.artists.forEach(artist => {
          let currentID = artist.id
          if (artist.artist) {
            currentID = artist.artist.id
          }
          exhib.artists.forEach(art => {
            let thisID = art.id
            if (art.artist) {
              thisID = art.artist.id
            }

            if (thisID === currentID && relatedExhibitionObj.length <= 3) {
              relatedExhibitionObj.push(exhib)
            }
          })
        })
      })

      if (relatedExhibitions.length === 0 && relatedExhibitionObj.length > 0) {
        // Ensure no related exhibitions duplicates are returned
        const ids = relatedExhibitionObj.map(o => o.id)
        // eslint-disable-next-line max-len
        const filtered = relatedExhibitionObj.filter(
          ({ id }, index) => !ids.includes(id, index + 1)
        )
        setRelatedExhibitions(filtered)
      }
    }
  })

  useEffect(() => {
    if (
      data.datoCmsSingleExhibition.id === "DatoCmsSingleExhibition-22414980-en"
    ) {
      const regex = /queer/g
      data.datoCmsSingleExhibition.description = data.datoCmsSingleExhibition.description.replace(
        regex,
        '<span class="redacted">queer</span>'
      )
    }
  }, [])

  return (
    <Layout
      bgcolor={exhibition.backgroundColor}
      wrapperClass="exhibition-single"
      accentColor={exhibition.accentColor}
    >
      <HelmetDatoCms seo={exhibition.seoMetaTags} />
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <Link to="/exhibitions" className="sidebar-text sidebar-text-first">
            Exhibitions
          </Link>
        </div>
        <div className="col-sm-9 col-xs-6 col">
          <h1
            className="gothic--xlarge large-title"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: exhibition.formattedTitle,
            }}
          />
        </div>
      </div>
      <div className="exhibition-main container row single-article-content">
        <div className="feature-image col-sm-9 col-xs-6 col">
					<ImageFadeIn
            src={exhibition.featuredImage.url}
            alt={exhibition.featuredImage.alt}
          />
          {exhibition.featuredImage.title && (
            <div className="col-sm-14 col-xs-6">
              <small
                className="caption"
                dangerouslySetInnerHTML={{
                  __html: exhibition.featuredImage.title,
                }}
              />
            </div>
          )}
        </div>
        <div className="main-info col-sm-4 col-xs-6 offset-sm-1 col">
          <h4 className="main-title sans--regular">
            {`${niceStartDate.date} ${niceStartDate.monthLong}`}
            &nbsp;-
            <br />
            {`${niceEndDate.date} ${niceEndDate.monthLong} ${niceEndDate.year}`}
          </h4>
          <div className="main-locations">
            {exhibition.locations.map(({ location, details, id }) => (
              <div key={id} className="location">
                <p className=" location-name sans--regular">{location.title}</p>
								{location.address && (
                <span className="location-address">{location?.address}</span>
								)}
                <div
                  className="location-dates"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: details,
                  }}
                />
              </div>
            ))}
          </div>
          <div className="main-divider">
            <DividerBlock accentColor={exhibition.accentColor} />
          </div>
        </div>
        {exhibition.description && (
          <div className="exhibition-info row single-article-content">
            <div
              className="content exhibition-description info col-sm-7 offset-sm-2 col-xs-6 col"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: exhibition.description,
              }}
            />
          </div>
        )}
				{exhibition.downloadFile && (
					<div
					className="download-file col-sm-7 offset-sm-2 col-xs-6 col"
				>
					<Link to={exhibition.downloadFile.url} target="_blank" rel="noreferrer" download={exhibition.downloadText}>
						<svg width="40" height="40" viewBox="0 0 40 40" fill="none">
							<circle cx="20" cy="20" r="19" stroke="black" strokeWidth="2" />
							<path d="M11.5791 23.1578L20.0002 31.5789L28.4212 23.1578" fill="none" stroke="black" strokeWidth="2" />
							<path d="M20 7.36841L20 31.5789" fill="none" stroke="black" strokeWidth="2" />
						</svg>
						<div className="text-wrapper">
							<p style={{ marginBottom: '0', textDecoration: 'underline' }}>{exhibition.downloadText}</p>
						</div>
					</Link>
				</div>
				)}

      </div>

      {exhibition.artists.length > 0 && (
        <div className="exhibition-artist-list container row">
          <div className="wrapper col-sm-7 offset-sm-2 col-xs-6 col">
            <div className="artist-list-wrapper">
              <p className="artist-title">
                <b>Artists</b>
              </p>
              <ul className="artist-list">
                {exhibition.artists.map(artist => renderArtist(artist))}
              </ul>
            </div>
          </div>
        </div>
      )}

      {exhibition.imageGallery.length > 0 && (
        <div className="swiper-gallery">
          <SwiperGallery slides={exhibition.imageGallery} />
        </div>
      )}

      {exhibition.externalVideo && (
        <div className="external-video col">
          <div className="container row col-sm-7 col-xs-6 offset-sm-2">
            <div className="video-wrapper">
              <ReactPlayer
                url={exhibition.externalVideo.url}
                height="100%"
                width="100%"
                controls
                muted
                loop
                title={exhibition.externalVideo.title}
                className="responsive-iframe"
              />
            </div>
          </div>
        </div>
      )}

			{exhibition.audioRecording && (
        <div className="event-recording container row">
          <div className="container col-sm-7 col-xs-6 col offset-sm-2">
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: exhibition.audioRecording,
              }}
            />
          </div>
        </div>
      )}

      {exhibition.publicPrograms.length > 0 && (
        <div className="exhibition-programs container row">
          <div className="programs-sidebar col-sm-2 col-xs-6 col">
            <span className="sidebar-text sidebar-text-everything">
              Public Program
            </span>
          </div>
          <div
            className="programs-content col col-sm-12"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {exhibition.publicPrograms.map(
              ({
                id,
                formattedTitle,
                location,
                event,
                excerpt,
                publicProgramType,
                featuredImage,
                slug,
              }) => {
                const prettyDate = event.length
                  ? formatDate(event[0]?.performanceDate)
                  : ""

                return (
                  <div className="itr image-text-row" key={id}>
                    <div className="image-text-row-image-wrapper">
                      <Link to={`/public-program/${slug}`}>
												<ImageFadeIn
													src={featuredImage.url}
													alt={featuredImage.alt}
												/>
                      </Link>
                    </div>
                    <div className="image-text-row-text-wrapper">
                      <Link to={`/public-program/${slug}`}>
                        <h3
                          className="sans--medium"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: formattedTitle,
                          }}
                        />
                      </Link>
                      <p className="date-info-wrapper">
                        <span>
                          <b>{publicProgramType?.title}</b>
                        </span>
                        <span>{`${prettyDate.day} ${prettyDate.date} ${prettyDate.monthLong}, ${prettyDate.hour}`}</span>
                        <span>{location?.title}</span>
                      </p>
                      {excerpt !== "" && (
                        <p
                          className="content excerpt"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: excerpt,
                          }}
                        />
                      )}
                      <Link
                        className="read-more"
                        to={`/public-program/${slug}`}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      )}

      {associatedWriting.length > 0 && (
					<div className="exhibition-associated-writing">
            {associatedWriting.map(
              ({
                id,
                formattedTitle,
                slug,
                excerpt,
                featuredImage,
                internal,
								author,
								articleType,
              }) => (
							<div className="container row">
								<div className="writing-sidebar col-sm-2 col-xs-6 col">
									<span className="sidebar-text">{articleType.title}</span>
								</div>
								<div className="writing-content col col-sm-12">
									<div className="itr image-text-row" key={id}>
										{cmsContentExists(featuredImage) && (
											<div className="image-text-row-image-wrapper">
												<Link
												to={`${slugPrefix(internal.type)}${slug}`} >
													<ImageFadeIn
															src={featuredImage.url}
															alt={featuredImage.alt}
														/>
												</Link>
											</div>
										)}
										<div className="image-text-row-text-wrapper">
											<Link to={`${slugPrefix(internal.type)}${slug}`} >
												<h3
													className="sans--medium"
													// eslint-disable-next-line react/no-danger
													dangerouslySetInnerHTML={{
														__html: formattedTitle,
												}}
											/>
											</Link>
											{excerpt !== "" && (
												<div
													className="content excerpt"
													// eslint-disable-next-line react/no-danger
													dangerouslySetInnerHTML={{
														__html: excerpt,
													}}
												/>
											)}
											{author && (
												<p>By {author}
												</p>
											)}
											<Link
												className="read-more"
												to={`${slugPrefix(internal.type)}${slug}`}
											>
												Read More
											</Link>
										</div>
									</div>
								</div>
							</div>	
              )
            )}
					</div>
      )}
      {exhibition.associatedPublications.length > 0 && (
					<div className="exhibition-associated-publication">
            <div className="container row">
              <div className="writing-sidebar col-sm-2 col-xs-6 col">
                <span className="sidebar-text">Publications</span>
              </div>
                {exhibition.associatedPublications.map(
                  ({
                    id,
                    formattedTitle,
                    slug,
                    featuredImage,
                    year,
                  }) => (
                    <div className="publication col col-sm-4 col-xs-3">
                      {cmsContentExists(featuredImage) && (
                        <Link
                        to={`/publications/${slug}`} >
                          <ImageFadeIn
                              src={featuredImage.url}
                              alt={featuredImage.alt}
                            />
                        </Link>
                      )}
                      <div className="image-text-row-text-wrapper">
                        <Link to={`/publications/${slug}`} >
                          <h3
                            className="sans--medium"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: formattedTitle,
                          }}
                          />
                          <span className="publication-year">{year}</span>
                        </Link>
                      </div>
                    </div>
                  )
                )}
                </div>	
              </div>
      )}
      <Credits
        creditText={exhibition.credit}
        creditLogos={exhibition.creditLogos}
      />
      <div className="exhibition-bottom-divider col-sm-5 col-xs-4 offset-xs-1 offset-sm-3 col spacing-0">
        <DividerBlock accentColor={exhibition.accentColor} />
      </div>

      {relatedExhibitions.length > 0 && (
        <div className="exhibition-related-exhibitions container row">
          <div className="col-sm-2 col-xs-6 col">
            <span className="sidebar-text">Related exhibitions</span>
          </div>
          <div className="tiles tiles-4 col-sm-12 col-xs-6 col row spacing-0">
            {Object.keys(relatedExhibitions).map(i => {
              const prettyStareDate = formatDate(
                relatedExhibitions[i].startDate
              )
              const prettyEndDate = formatDate(relatedExhibitions[i].endDate)
              return (
                <div className="tile col" key={relatedExhibitions[i].id}>
                  <Link
                    to={`/exhibition/${relatedExhibitions[i].slug}`}
                    className="exhibition-tile"
                  >
											<ImageFadeIn
													src={relatedExhibitions[i].featuredImage.url}
													alt={relatedExhibitions[i].featuredImage.alt}
												/>
                    <div className="tile-text-wrapper">
                      <h3
                        className="exhibition-title sans--regular"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: relatedExhibitions[i].formattedTitle,
                        }}
                      />
                      <p className="exhibition-location">
                        {relatedExhibitions[i].locations[0]?.location?.title}
                      </p>
                      <p className="exhibition-dates">
                        {`${prettyStareDate.date} ${prettyStareDate.month}`}
                        &nbsp;-&nbsp;
                        {`${prettyEndDate.date} ${prettyEndDate.month} ${prettyEndDate.year}`}
                      </p>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </Layout>
  )
}

Exhibition.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Exhibition

export const query = graphql`
  query ExhibitionQuery($slug: String!) {
    datoCmsSingleExhibition(slug: { eq: $slug }) {
      id
      backgroundColor
      accentColor {
        hex
      }
      formattedTitle
      featuredImage {
				url(imgixParams: { h: "800", ar: "5:3", fit: "crop", crop: "focalpoint" })
        alt
        title
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      startDate
      endDate
      imageGallery {
        title
        url(imgixParams: { h: "800", fit: "clip" })
      }
      externalVideo {
        url
        providerUid
        title
      }
      locations {
        id
        details
        location {
          id
          title
          address
        }
      }
      artists {
        ... on DatoCmsStudioArtist {
          artist {
            id
            slug
            name
          }
        }
        ... on DatoCmsNonStudioArtist {
          id
          artistName
        }
      }
      description
			downloadText
			downloadFile {
				url
			}
			audioRecording
			credit
      creditLogos {
        alt
        fixed(height: 50, imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsFixed
        }
      }
      publicPrograms {
        formattedTitle
        publicProgramType {
          title
        }
        event {
          ... on DatoCmsDate {
            id
            performanceDate
          }
        }
        excerpt
        slug
        id
        location {
          id
          title
        }
        featuredImage {
					url(imgixParams: { w: "600", ar: "5:3", fit: "crop", crop: "focalpoint" })
					alt
        }
      }
      associatedWriting {
        ... on DatoCmsSingleArticle {
          id
          internal {
            type
          }
          formattedTitle
					author
          slug
					articleType {
						title
					}
          excerpt
          featuredImage {
						url(imgixParams: { w: "600", ar: "5:3", fit: "crop", crop: "focalpoint" })
						alt
          }
        }
      }
      associatedPublications {
        ... on DatoCmsSinglePublication {
          id
          internal {
            type
          }
          formattedTitle
          slug
					category {
						title
					}
          year
          details
          featuredImage {
						url(imgixParams: { w: "600", ar: "1:1", fit: "crop", crop: "focalpoint" })
						alt
          }
        }
      }
      relatedExhibitions {
        id
        featuredImage {
					url(imgixParams: { w: "450", ar: "5:3", fit: "crop", crop: "focalpoint" })
					alt
        }
        endDate
        startDate
        formattedTitle
        slug
        locations {
          location {
            title
          }
        }
      }
    }
    allDatoCmsSingleExhibition(
      sort: { fields: [meta___publishedAt], order: DESC }
      filter: { meta: { isValid: { eq: true }, status: { ne: "draft" } } }
    ) {
      edges {
        node {
          id
          featuredImage {
						url(imgixParams: { w: "444" h: "250", fit: "crop", crop: "focalpoint" })
						alt
          }
          artists {
            ... on DatoCmsStudioArtist {
              artist {
                id
                slug
                name
              }
            }
            ... on DatoCmsNonStudioArtist {
              id
              artistName
            }
          }
          endDate
          startDate
          formattedTitle
          slug
          locations {
            location {
              title
            }
          }
        }
      }
    }
  }
`
